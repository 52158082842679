import { createSlice } from '@reduxjs/toolkit'

export const ChatSlice = createSlice({
  name: 'chat',
  initialState: {
    chatList: [],
    messageList: [],
    activeChatId: null,
    activeUser: null,
    responsiveChat: false
  },
  reducers: {
    setChatList: (state, action) => {
      state.chatList = action.payload
    },
    setMessageList: (state, action) => {
      state.messageList = action.payload
    },
    setActiveChatId: (state, action) => {
      state.activeChatId = action.payload
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload
    },
    setResponsiveChat: (state, action) => {
      state.responsiveChat = action.payload
    },
    addMessageToList: (state, action) => {
      const newMessage = action.payload
      // Check if the message already exists in the list based on the _id
      const messageIndex = state.messageList.findIndex(msg => msg._id === newMessage._id)
      if (messageIndex === -1) {
        // If the message doesn't exist, push it to the list
        state.messageList.push(newMessage)
      }
    },
    updateChatList: (state, action) => {
      const newConversation = action.payload

      // Find index of the conversation in the chat list
      const conversationIndex = state.chatList.findIndex(chat => chat._id === newConversation._id)

      let updatedChatList
      if (conversationIndex !== -1) {
        // If the conversation exists, update it
        updatedChatList = [...state.chatList]
        updatedChatList[conversationIndex] = newConversation
      } else {
        // If the conversation doesn't exist, add it
        updatedChatList = [...state.chatList, newConversation]
      }

      // Sort the chat list by `lastMsg.createdAt` in descending order
      state.chatList = updatedChatList.sort((a, b) => {
        const lastMsgA = a?.lastMsg
        const lastMsgB = b?.lastMsg

        if (!lastMsgA || !lastMsgB) {
          return 0 // Keep the same order if one of the messages is missing
        }

        const createdAtA = new Date(lastMsgA.createdAt)
        const createdAtB = new Date(lastMsgB.createdAt)
        return createdAtB - createdAtA // Sort by newest message first
      })
    }
  }
})

export const { setChatList, setMessageList, updateChatList, addMessageToList, setActiveChatId, setResponsiveChat, setActiveUser } = ChatSlice.actions

export default ChatSlice.reducer